'use client';

import "./globals.css";
import "./bg.css";
import { 
  dm_sans,
  nova_round,
  noto_sans,
  noto_serif_hebrew,
  space_mono,
  quattrocento_sans
} from "./style/fonts";
import Navbar from "../components/Navbar";
import { ReadingProvider } from "../contexts/ReadingContext";
import { UserProvider } from "../contexts/UserContext";
import { StripeProvider } from "../contexts/StripeContext";
import { LayoutProvider } from "../contexts/LayoutContext";
import { CopilotKit } from "@copilotkit/react-core";
import "@copilotkit/react-ui/styles.css";
import { SnackbarProvider } from "notistack";
import { useEffect } from 'react';
import { initViewportHeight } from '@/lib/utils/viewport';
import { AstrologicalProvider } from "@/contexts/astrology";
import { SpeedInsights } from "@vercel/speed-insights/next"
import { Analytics } from "@vercel/analytics/react"

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    return initViewportHeight();
  }, []);

  return (
    <html lang="en" className={`
      ${dm_sans.variable}
      ${nova_round.variable}
      ${space_mono.variable}
      ${noto_sans.variable}
      ${noto_serif_hebrew.variable}
      ${quattrocento_sans.variable}
    `}>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover" />
      </head>
      <body className={`flex flex-col min-h-full`}>
        <UserProvider>
          <StripeProvider>
            <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
              <LayoutProvider>
                <ReadingProvider>
                  <CopilotKit runtimeUrl="/api/copilotkit">
                    <AstrologicalProvider>
                      <Navbar />
                      <main className="flex-grow flex-col transition-all duration-300 ease-in-out">
                        {children}
                      </main>
                    </AstrologicalProvider>
                  </CopilotKit>
                </ReadingProvider>
              </LayoutProvider>
            </SnackbarProvider>
          </StripeProvider>
        </UserProvider>
        <SpeedInsights />
        <Analytics/>
      </body>
    </html>
  );
}
