'use client'

import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { useRouter, usePathname } from 'next/navigation';
import { Zap, LogOut, CreditCard, UserCircle, Coins } from 'lucide-react';
import Image from 'next/image';
import { useUser } from '@/contexts/UserContext';
import TokenDisplay from './tokens/TokenDisplay';
import ProductHuntBadge from './home/ProductHuntBadge';

const Navbar = () => {
  const { user, userProfile, signOut } = useUser();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const pathname = usePathname();

  const isReadingPage = pathname?.includes('/readings/');
  const isHomePage = pathname === '/';

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsDropdownOpen(false);
      router.push('/');
    } catch (error) {
      console.error('Error signing out:', error);
      // Error is handled by context
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Close dropdown when route changes
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [pathname]);

  const textColorClass = isReadingPage ? 'text-gray-500' : 'text-primary';

  return (
    <nav className={`
      sm:bg-transparent fixed top-0 left-0 right-0 z-50 animate-in fade-in slide-in-from-top-5
      ${isHomePage ? 'bg-transparent' : 'bg-gray-100'}
    `}>
      <div className="mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="flex space-x-7">
            <div className="flex items-center">
              <Link href="/" className="flex items-center py-4 px-2" prefetch={false}>
                <span className={` ${textColorClass} font-heading tracking-tight text-sm sm:text-md`}>Arcana Vibes</span>
              </Link>
              <div className={`${textColorClass} hidden sm:block`}>
                <TokenDisplay />
              </div>
            </div>
          </div>
          <div className="flex items-center sm:space-x-3">
            <div className={`${textColorClass} sm:hidden`}>
              <TokenDisplay />
            </div>
            <div className="hidden sm:block pt-1">
              <ProductHuntBadge />  
            </div>
            {user && userProfile ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center sm:space-x-2 focus:outline-none pr-0 sm:pr-2"
                >
                  <span className={` ${textColorClass} text-sm sm:text-md hidden sm:block`}>
                    Welcome, {userProfile.firstName}!
                  </span>
                  <Image
                    className="h-8 w-8 rounded-full"
                    src={userProfile.displayPictureUrl || '/placeholder.jpg?height=32&width=32'}
                    alt={`${userProfile.firstName} ${userProfile.lastName}`}
                    width={32}
                    height={32}
                  />
                </button>
                {isDropdownOpen && (
                  <div
                    className={`absolute 
                    right-0 
                    mt-2 
                    w-48 
                    ${isReadingPage ? 'bg-white/50' : 'bg-white/50'} 
                    backdrop-blur-sm 
                    rounded-xl 
                    overflow-hidden 
                    shadow-xl 
                    z-10 
                    transform origin-top-right transition-all duration-200 ease-out
                    animate-in fade-in slide-in-from-top-5`}
                  >
                    <Link 
                      href={`/${userProfile.handle}/profile`} 
                      className={`block px-4 py-2 text-base ${textColorClass} hover:bg-white/20 transition-colors`} 
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <UserCircle className="h-4 w-4 mr-2" />
                        Profile
                      </div>
                    </Link>
                    
                    <Link 
                      href={`/${userProfile.handle}/account`} 
                      className={`block px-4 py-2 text-base ${textColorClass} hover:bg-white/20 transition-colors`} 
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <CreditCard className="h-4 w-4 mr-2" />
                        Account
                      </div>
                    </Link>
                    <Link 
                      href={`/upgrade`} 
                      className={`block px-4 py-2 text-base ${textColorClass} hover:bg-white/20 transition-colors`} 
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <Zap className="h-4 w-4 mr-2" />
                        Subscribe
                      </div>
                    </Link>
                    <Link 
                      href="/tokens" 
                      className={`block px-4 py-2 text-base ${textColorClass} hover:bg-white/20 transition-colors`} 
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <Coins className="h-4 w-4 mr-2" />
                        Buy Tokens
                      </div>
                    </Link>
                    <button 
                      onClick={() => { handleSignOut(); }} 
                      className={`block w-full text-left px-4 py-2 text-base ${textColorClass} hover:bg-white/20 transition-colors`}
                    >
                      <div className="flex items-center font-subheading">
                        <LogOut className="h-4 w-4 mr-2" />
                        Sign Out
                      </div>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center space-x-2 focus:outline-none pr-0"
                >
                  <UserCircle className={`h-8 w-8 ${textColorClass}`} />
                </button>
                {isDropdownOpen && (
                  <div
                    className={`absolute 
                    right-0 
                    mt-2 
                    w-48 
                    ${isReadingPage ? 'bg-white/50' : 'bg-white/50'} 
                    backdrop-blur-sm 
                    rounded-xl 
                    overflow-hidden 
                    shadow-xl 
                    z-10 
                    transform origin-top-right transition-all duration-200 ease-out
                    animate-in fade-in slide-in-from-top-5`}
                  ><Link 
                  href="/signup" 
                  className={`block px-4 py-2 text-base ${textColorClass} hover:bg-white/20 transition-colors`} 
                  prefetch={false}
                >
                  <div className="flex items-center font-subheading">
                    <LogOut className="h-4 w-4 mr-2" />
                    Sign Up
                  </div>
                </Link>
                    <Link 
                      href="/signin" 
                      className={`block px-4 py-2 text-base ${textColorClass} hover:bg-white/20 transition-colors`} 
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <LogOut className="h-4 w-4 mr-2" />
                        Sign In
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;