import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const fullConfig = resolveConfig(tailwindConfig)

// Type for the screens object from Tailwind config
type Screens = typeof fullConfig.theme.screens

// Extract breakpoint values and convert them to numbers
export const breakpoints = Object.entries(fullConfig.theme.screens as Screens)
  .reduce((acc, [key, value]) => {
    // Convert values like '640px' to numbers (640)
    const numericValue = parseInt(value.replace('px', ''), 10)
    return {
      ...acc,
      [key]: numericValue
    }
  }, {} as Record<keyof Screens, number>)

// Type for breakpoint keys
export type Breakpoint = keyof typeof breakpoints

// Utility function to get a breakpoint value
export function getBreakpointValue(breakpoint: Breakpoint): number {
  return breakpoints[breakpoint]
} 