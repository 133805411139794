'use client'

import { createContext, useContext, useState, useCallback } from 'react'
import type { Reading } from '@/lib/types'

interface ReadingContextType {
  currentReading: Reading | null
  setCurrentReading: (reading: Reading | null) => void
  readingId: string | null
  setReadingId: (id: string | null) => void
}

const ReadingContext = createContext<ReadingContextType | undefined>(undefined)

export const ReadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentReading, setCurrentReading] = useState<Reading | null>(null)
  const [readingId, setReadingId] = useState<string | null>(null)

  const handleSetReading = useCallback((reading: Reading | null) => {
    setCurrentReading(reading)
    setReadingId(reading?.id || null)
  }, [])

  return (
    <ReadingContext.Provider 
      value={{ 
        currentReading, 
        setCurrentReading: handleSetReading,
        readingId,
        setReadingId
      }}
    >
      {children}
    </ReadingContext.Provider>
  )
}

export const useReading = () => {
  const context = useContext(ReadingContext)
  if (!context) {
    throw new Error('useReading must be used within a ReadingProvider')
  }
  return context
} 