import React, { createContext, useContext, useState, useCallback } from 'react';
import { useBreakpoint } from '@/hooks/useBreakpoint';

export type View = 'spread' | 'details' | 'chat';

interface LayoutContextType {
  currentView: View;
  setView: (view: View) => void;
  isMobile: boolean;
  isDesktop: boolean;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export function LayoutProvider({ children }: { children: React.ReactNode }) {
  const [currentView, setCurrentView] = useState<View>('spread');
  const { isMobile, isDesktop } = useBreakpoint();

  const setView = useCallback((view: View) => {
    setCurrentView(view);
  }, []);

  return (
    <LayoutContext.Provider value={{ currentView, setView, isMobile, isDesktop }}>
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
} 