'use client';

import { createContext, useContext } from 'react';
import type { ChartData, DerivedData, TransitData } from '@/lib/types/astrology';

export interface AstrologicalState {
  natal: {
    data: ChartData | null;
    status: 'pending' | 'in_progress' | 'completed' | 'failed';
    error?: string;
  };
  transit: {
    data: TransitData | null;
    status: 'pending' | 'in_progress' | 'completed' | 'failed';
    error?: string;
  };
  derived: {
    data: DerivedData | null;
    status: 'pending' | 'in_progress' | 'completed' | 'failed';
    error?: string;
  };
}

export const AstrologicalContext = createContext<AstrologicalState | null>(null);

export function useAstrological(): AstrologicalState {
  const context = useContext(AstrologicalContext);
  if (!context) {
    throw new Error('useAstrological must be used within an AstrologicalProvider');
  }
  return context;
} 