'use client';

import { ReactNode, useEffect, useState } from 'react';
import { AstrologicalContext, type AstrologicalState } from './AstrologicalContext';
import { useUser } from '@/contexts/UserContext';
import { fetchNatalChart, fetchTransitData, fetchDerivedData } from '@/services/astrologyService';

const initialState: AstrologicalState = {
  natal: {
    data: null,
    status: 'pending'
  },
  transit: {
    data: null,
    status: 'pending'
  },
  derived: {
    data: null,
    status: 'pending'
  }
};

export function AstrologicalProvider({ children }: { children: ReactNode }) {
  const { user } = useUser();
  const [state, setState] = useState<AstrologicalState>(initialState);

  useEffect(() => {
    if (!user?.uid) {
      setState(initialState);
      return;
    }

    const userId = user.uid;

    async function loadData() {
      try {
        // Fetch natal chart first
        const natalResult = await fetchNatalChart(userId);
        setState(prev => ({
          ...prev,
          natal: natalResult
        }));

        // Only fetch transit and derived if we have natal data
        if (natalResult.data?.id) {
          const [transitResult, derivedResult] = await Promise.all([
            fetchTransitData(userId, natalResult.data.id),
            fetchDerivedData(userId, natalResult.data.id)
          ]);

          setState(prev => ({
            ...prev,
            transit: transitResult,
            derived: derivedResult
          }));
        }
      } catch (error) {
        console.error('Error loading astrological data:', error);
        setState(prev => ({
          ...prev,
          natal: { data: null, status: 'failed', error: 'Failed to load data' },
          transit: { data: null, status: 'failed', error: 'Failed to load data' },
          derived: { data: null, status: 'failed', error: 'Failed to load data' }
        }));
      }
    }

    loadData();
  }, [user?.uid]);

  return (
    <AstrologicalContext.Provider value={state}>
      {children}
    </AstrologicalContext.Provider>
  );
} 