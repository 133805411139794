'use client';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import { createContext, useContext, useEffect, useState } from 'react';

interface StripeContextType {
  stripe: Stripe | null;
  isLoading: boolean;
}

const StripeContext = createContext<StripeContextType>({
  stripe: null,
  isLoading: true,
});

export const useStripe = () => useContext(StripeContext);

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    const key = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
    if (!key) {
      throw new Error('Stripe publishable key is not configured');
    }
    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

export function StripeProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getStripe()
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error('Failed to load Stripe:', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Elements stripe={getStripe()}>
      <StripeContext.Provider value={{ stripe: getStripe(), isLoading }}>
        {children}
      </StripeContext.Provider>
    </Elements>
  );
}
