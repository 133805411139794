'use client'

import React from 'react'
import { useUser } from '@/contexts/UserContext'
import { motion } from 'framer-motion'
import { Coins } from 'lucide-react'
import Link from 'next/link'

export default function TokenDisplay() {
  const { userProfile } = useUser()
  const tokenAllowance = userProfile?.tokenAllowance

  if (!tokenAllowance) {
    return null
  }

  const totalRemaining = 
    tokenAllowance.subscriptionTokens.remaining + 
    tokenAllowance.nonExpiringTokens.remaining

  return (
    <Link href="/tokens">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center gap-2 ml-4 hover:opacity-80 transition-opacity cursor-pointer"
      >
        <Coins className="h-5 w-5 text-inherit opacity-70" />
        <span className="text-base text-inherit">
          {totalRemaining.toLocaleString()}
        </span>
      </motion.div>
    </Link>
  )
}