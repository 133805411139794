import { collection, doc, getDocs, getDoc, query, where, limit } from 'firebase/firestore';
import { db } from '@/lib/firebase/firebase';
import type { ChartData, DerivedData, TransitData } from '@/lib/types/astrology';
import { Timestamp } from 'firebase/firestore';

// Firestore types that match the exact shape of the data in Firestore
interface FirestoreChartData {
  id?: string;
  type: ChartData['type'];
  date: Timestamp;
  location: ChartData['location'];
  settings: ChartData['settings'];
  planets: ChartData['planets'];
  houses: ChartData['houses'];
  angles: ChartData['angles'];
  fixedStars?: ChartData['fixedStars'];
  arabicParts?: ChartData['arabicParts'];
  derived?: {
    aspects: DerivedData['aspects'];
    patterns: DerivedData['patterns'];
    elementalBalance: DerivedData['elementalBalance'];
    dispositorChains: DerivedData['dispositorChains'];
    status: DerivedData['status'];
    lastUpdated: Timestamp;
  };
  metadata?: ChartData['metadata'];
}

export async function fetchNatalChart(userId: string): Promise<{
  data: ChartData | null;
  status: 'pending' | 'in_progress' | 'completed' | 'failed';
  error?: string;
}> {
  try {
    // First get the user profile to verify access
    const userProfileRef = doc(db, 'userProfiles', userId);
    const userProfileDoc = await getDoc(userProfileRef);
    
    if (!userProfileDoc.exists()) {
      return {
        data: null,
        status: 'failed',
        error: 'User profile not found'
      };
    }

    // Then query the charts subcollection
    const userChartsRef = collection(userProfileRef, 'charts');
    const q = query(userChartsRef, 
      where('type', '==', 'Natal'),
      where('metadata.isPrimary', '==', true),
      limit(1)
    );

    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return {
        data: null,
        status: 'completed',
        error: 'No natal chart found'
      };
    }

    const chartDoc = snapshot.docs[0];
    const firestoreData = chartDoc.data() as FirestoreChartData;
    
    // Convert Firestore data to app data
    const chartData: ChartData = {
      id: chartDoc.id,
      ...firestoreData,
      date: firestoreData.date.toDate(),
      derived: firestoreData.derived ? {
        ...firestoreData.derived,
        lastUpdated: firestoreData.derived.lastUpdated.toDate()
      } : undefined
    };

    return {
      data: chartData,
      status: 'completed'
    };
  } catch (error) {
    console.error('Error fetching natal chart:', error);
    return {
      data: null,
      status: 'failed',
      error: error instanceof Error ? error.message : 'Error accessing chart data'
    };
  }
}

export async function fetchTransitData(userId: string, chartId: string): Promise<{
  data: TransitData | null;
  status: 'pending' | 'in_progress' | 'completed' | 'failed';
  error?: string;
}> {
  try {
    const today = new Date().toISOString().split('T')[0];
    const transitRef = doc(db, 'userProfiles', userId, 'charts', chartId, 'transit', today);
    const transitDoc = await getDoc(transitRef);
    
    if (!transitDoc.exists()) {
      return {
        data: null,
        status: 'completed',
        error: 'No transit data found'
      };
    }
    
    const firestoreData = transitDoc.data();
    
    // Convert Firestore data to app data
    const transitData: TransitData = {
      natalChartId: chartId,
      transitChart: firestoreData.transitChart,
      movements: firestoreData.movements.map((m: any) => ({
        ...m,
        entryDate: m.entryDate.toDate(),
        exitDate: m.exitDate.toDate()
      })),
      timestamp: firestoreData.timestamp.toDate(),
      timezone: firestoreData.timezone,
      lastUpdated: firestoreData.lastRefreshed?.toDate()
    };

    return {
      data: transitData,
      status: 'completed'
    };
  } catch (error) {
    console.error('Error fetching transit data:', error);
    return {
      data: null,
      status: 'failed',
      error: error instanceof Error ? error.message : 'Unknown error'
    };
  }
}

export async function fetchDerivedData(userId: string, chartId: string): Promise<{
  data: DerivedData | null;
  status: 'pending' | 'in_progress' | 'completed' | 'failed';
  error?: string;
}> {
  try {
    const derivedRef = doc(db, 'userProfiles', userId, 'charts', chartId, 'derived', 'data');
    const derivedDoc = await getDoc(derivedRef);
    
    if (!derivedDoc.exists()) {
      return {
        data: null,
        status: 'completed',
        error: 'No derived data found'
      };
    }
    
    const firestoreData = derivedDoc.data() as FirestoreChartData['derived'];
    
    if (!firestoreData) {
      return {
        data: null,
        status: 'failed',
        error: 'Derived data is missing'
      };
    }

    // Ensure all required fields are present
    if (!firestoreData.aspects || !firestoreData.patterns || 
        !firestoreData.elementalBalance || !firestoreData.dispositorChains) {
      return {
        data: null,
        status: 'failed',
        error: 'Derived data is incomplete'
      };
    }

    // Convert Firestore data to app data
    const derivedData: DerivedData = {
      aspects: firestoreData.aspects,
      patterns: firestoreData.patterns,
      elementalBalance: firestoreData.elementalBalance,
      dispositorChains: firestoreData.dispositorChains,
      status: firestoreData.status,
      lastUpdated: firestoreData.lastUpdated.toDate()
    };

    return {
      data: derivedData,
      status: firestoreData.status
    };
  } catch (error) {
    console.error('Error fetching derived data:', error);
    return {
      data: null,
      status: 'failed',
      error: error instanceof Error ? error.message : 'Unknown error'
    };
  }
} 